body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.app-title {
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: rebeccapurple;
}

.form-container {
  text-align: center;
  margin-bottom: 20px;
  max-width: 400px; 
  margin: 0 auto;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: whitesmoke;
}

.form-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 15px;
}

.form-button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: whitesmoke; /* Background color for the table */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Box shadow for the table */
}

/* Header Row Styles */
.table-header {
  background-color: #007bff; /* Header background color */
  color: #fff; /* Header text color */
  font-weight: bold;
}

/* Table Row Styles */
.table-row:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row background color */
}

.table-row:hover {
  background-color: #dcdcdc; /* Hovered row background color */
  cursor: pointer; /* Change cursor on hover */
}

/* Table Cell Styles */
.table th,
.table td {
  border: 1px solid #ddd; /* Border for table cells */
  padding: 8px;
  text-align: left;
}

/* Title and Contents Cell Styles */
.table td:nth-child(2),
.table td:nth-child(3) {
  font-weight: bold; /* Make title and contents cells bold */
}

/* Created Cell Styles */
.table td:nth-child(4) {
  font-style: italic; /* Make created cell italic */
}



.delete-div {
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px; 
  margin: 0 auto;
  
}

.delete-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: whitesmoke;
}

.delete-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.delete-button:hover {
  background-color: #c82333;
}

















/* 
.form-inputs{
  margin-left: 200px;
 margin-top: 50px;
}

.form-button{
  margin-left: 50px;
  margin-top: 50px;
}

.delete-div{
  margin-top: 50px;
  margin-left: 50px;
} */
